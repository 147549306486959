import _ from 'lodash'
import styled from 'styled-components'
import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types'
import { getAssetSrc, getAssetType, getAssetTitle } from '../../utils/resources'
import media from '../../config/theme/media'
import { bodyFontColor, grey, primary } from '../../config/theme/colors'

import WebViewLink from '../../components/WebViewLink'
import H2 from '../../components/Typography/H2'
import H3 from '../../components/Typography/H3'
import H4 from '../../components/Typography/H4'
import P from '../../components/Typography/P'
import Accordion from '../../components/Accordion'
import renderRichText from './renderRichText'

const Image = styled.img`
  max-width: 100%;
`
const Video = styled.video`
  max-width: 100%;
`
const PositionedVideo = styled.video`
  width: ${props => (props.align ? 'auto' : props.rowLength ? `${100 / props.rowLength}%` : '100%')};
  padding: 2px;
  box-sizing: border-box;
  height: ${props => props.rowHeight ? `${props.rowHeight}px` : props.rowLength ? '200px' : 'auto'};
  object-fit: ${props => (props.shrinkImageToFitFrame ? 'contain' : 'cover')};
  height: ${props => props.rowHeight ? `${props.rowHeight}px` : props.rowLength ? '200px' : 'auto'};
  object-fit: ${props => (props.shrinkImageToFitFrame ? 'contain' : 'cover')};
  object-position: center;
  margin: ${props => (props.align === 'center' ? 'auto auto' : '0')}; 
  ${props => props.oneColumnMobile && !props.align && media.lessThan('md')`
    width: 100%;
  `}
`

const IframeContainer = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  width: 100%;

  & iframe,
  & object,
  & embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`

const DangerousHtmlWrapper = styled.div`
  display: flex;
  flex: 1;
`

const ContentfulCodeWrapper = styled(DangerousHtmlWrapper)`
div {
  display:flex;
  flex:1;
  justify-content: flex-start;
  align-items: flex-start;

  & iframe {
    flex:1;
    border: 0;
    height: 35em;
    }
}  
`
const AccordionContentContainer = styled.div`
padding: 20px;
`
const MediaGallery = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  ${props => props.oneColumnMobile && media.lessThan('md')`
  flex-direction: column;
`}
`

const PositionedMediaImg = styled.img`
  width: ${props => (props.align ? 'auto' : props.rowLength ? `${100 / props.rowLength}%` : '100%')};
  box-sizing: border-box;
  height: ${props => props.rowHeight ? `${props.rowHeight}px` : props.rowLength ? '200px' : 'auto'};
  object-fit: ${props => (props.shrinkImageToFitFrame ? 'contain' : 'cover')};
  height: ${props => props.rowHeight ? `${props.rowHeight}px` : props.rowLength ? '200px' : 'auto'};
  object-fit: ${props => (props.shrinkImageToFitFrame ? 'contain' : 'cover')};
  object-position: center;
  margin: ${props => (props.align === 'center' ? 'auto auto' : props.align === 'right' ? '0 0 0 auto' : '0')};
  
  ${media.lessThan('md')`
    padding-right: 0;
    width: 50%;
    height: auto;
    margin: ${props => (props.align === 'center' ? 'auto' : props.align === 'right' ? '0 0 0 auto' : '0')};
  `}
  
  margin: ${props => (props.align === 'center' ? 'auto auto' : props.align === 'right' ? '0 0 0 auto' : '0')};
  
  ${media.lessThan('md')`
    padding-right: 0;
    width: 50%;
    height: auto;
    margin: ${props => (props.align === 'center' ? 'auto' : props.align === 'right' ? '0 0 0 auto' : '0')};
  `}
  
  ${props => props.oneColumnMobile && !props.align && media.lessThan('md')`
    width: 100%;
  `}
`
const ImgContainer = styled.div`
  display: flex;
  justify-content: ${props => {
    switch (props.align) {
      case 'center':
          return 'center'
      case 'right':
          return 'flex-end'
      default:
          return 'flex-start'
    }
    }
  }};
  width: 100%;
  padding: 0;
  box-sizing: border-box;
  max-width: 100%;
  overflow: hidden;
  padding: 0;
  box-sizing: border-box;
  max-width: 100%;
  overflow: hidden;
`

const Heading2 = ({ children, id }) => <H2 id={id}>{children}</H2>
const Heading3 = ({ children, id }) => <H3 id={id}>{children}</H3>
const Heading4 = ({ children, id }) => <H4 id={id}>{children}</H4>
const Paragraph = ({ children }) => <P>{children}</P>

const MediaComponent = ({ src, alt, assetType, align, rowHeight, rowLength, oneColumnMobile, shrinkImageToFitFrame, isPositionedMedia }) => {
  const isVideo = assetType?.startsWith('video/')
  return (
    <>
      {isVideo ? (
        isPositionedMedia ? 
        <PositionedVideo align={align} src={src} rowLength={rowLength} rowHeight={rowHeight} oneColumnMobile={oneColumnMobile} shrinkImageToFitFrame={shrinkImageToFitFrame} controls>
          <source src={src} type={assetType} />
          <p>Your browser doesn't support HTML5 video.</p>
        </PositionedVideo>
        : <Video src={src} controls></Video>
      ) : (
        isPositionedMedia ? 
          <PositionedMediaImg align={align} src={src} rowLength={rowLength} rowHeight={rowHeight} alt={alt} oneColumnMobile={oneColumnMobile} shrinkImageToFitFrame={shrinkImageToFitFrame} /> 
          : <Image src={src} alt={alt} />
      )}
    </>
  )
}

const IframeComp = ({ src, id, title, ...rest }) => (
  <IframeContainer>
    <iframe
      title={title}
      width='853'
      height='480'
      src={src}
      frameBorder='0'
      allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
      allowFullScreen
      {...rest}
    />
  </IframeContainer>
)

const stringToElementId = (value) => value.replace(/[\W_]+/g, '-').toLowerCase()

const getHyperlinkObject = (hyperlinkNode) => {
  const youtubeUriHead = 'https://www.youtube.com/watch?v='
  const youtubeEmbedHead = 'https://www.youtube.com/embed/'

  const title = _.get(hyperlinkNode, 'content.0.value', '')
  const uri = _.get(hyperlinkNode, 'data.uri')
  const externalLink = _.includes(uri, ':')
  const target = externalLink ? '_blank' : '_self'

  const isYoutube = uri.includes(youtubeUriHead)

  return isYoutube
    ? {
      title,
      type: 'iframe',
      platform: 'youtube',
      src: uri.replace(youtubeUriHead, youtubeEmbedHead)
    }
    : { title, type: 'hyperlink', src: uri, target }
}

const getEmbeddedEntry = (embeddedEntryNode) => {
  return {
    type : _.get(embeddedEntryNode, 'data.target.sys.contentType.sys.id', null),
    id: _.get(embeddedEntryNode, 'data.target.sys.id', null),
    fields: _.get(embeddedEntryNode, 'data.target.fields', null)
  }
}

const getAccordion = (fields) => {
  return _.map(fields.fields.accordionItem, (item) => ({
    title: _.get(item,'fields.header'),
    content: <AccordionContentContainer>{renderRichText(_.get(item,'fields.content'))}</AccordionContentContainer>
  }))
}

const getMediaGallery = (media) => {
  const {rowLength, rowHeight, oneColumnMobile, shrinkImageToFitFrame} = media.fields
  return media.fields.media.map(asset => {
    const assetType = getAssetType(asset)
    const src = getAssetSrc(asset)
    const alt = getAssetTitle(asset)
    return <MediaComponent key={asset.sys.id} alt={alt} src={src} assetType={assetType} rowLength={rowLength} rowHeight={rowHeight} oneColumnMobile={oneColumnMobile} shrinkImageToFitFrame={shrinkImageToFitFrame} isPositionedMedia={true}
/>
  })
}

const options = {
  renderMark: {
    [MARKS.CODE]: (content) =>
      (<ContentfulCodeWrapper>
        <DangerousHtmlWrapper dangerouslySetInnerHTML={{ __html: content }}></DangerousHtmlWrapper>
      </ContentfulCodeWrapper>)
  },
  renderNode: {
    [BLOCKS.HEADING_2]: (node, children) => (
      <Heading2 id={stringToElementId(node.content[0].value)}>
        {children}
      </Heading2>
    ),
    [BLOCKS.HEADING_3]: (node, children) => (
      <Heading3 id={stringToElementId(node.content[0].value)}>
        {children}
      </Heading3>
    ),
    [BLOCKS.HEADING_4]: (node, children) => (
      <Heading4 id={stringToElementId(node.content[0].value)}>
        {children}
      </Heading4>
    ),
    [BLOCKS.PARAGRAPH]: (node, children) => <Paragraph>{children}</Paragraph>,
    'embedded-asset-block': (node) => {
      const assetType = getAssetType(node.data.target)
      const src = getAssetSrc(node.data.target)
      return (
        <MediaComponent
          alt={getAssetTitle(node.data.target)} 
          src={src} 
          assetType={assetType} 
        />
      )
    },
    [BLOCKS.EMBEDDED_ENTRY] :(node) => {
      const embeddedEntry = getEmbeddedEntry(node)
      switch (embeddedEntry.type) {
        case 'accordion':
          return <Accordion accordionData={getAccordion(embeddedEntry)} headerColor={primary()} contentColor={grey()}/> 
        case 'mediaGallery':
          const { oneColumnMobile } = embeddedEntry.fields
          return <MediaGallery oneColumnMobile={oneColumnMobile}>
            {getMediaGallery(embeddedEntry)}
             </MediaGallery>
        case 'positionedMediaAsset':
          const { alignment, media, name } = embeddedEntry.fields
          return <ImgContainer align={alignment}>
                  <MediaComponent 
                    alt={name}
                    src={getAssetSrc(media)} 
                    assetType={getAssetType(media)} 
                    align={alignment}
                    isPositionedMedia={true}
                  />
                </ImgContainer>
        default:
          return null
      }
      },
    [INLINES.HYPERLINK]: (node, children) => {
      const hyperlinkObj = getHyperlinkObject(node)
      switch (hyperlinkObj.type) {
        case 'iframe':
          return <IframeComp {...hyperlinkObj} />
        default:
          return (
            <WebViewLink url={node.data.uri} {...hyperlinkObj}>
              {children}
            </WebViewLink>
          )
      }
    },
  },

    }


export default options
